/**
 * Copyright 2020, SumUp Ltd.
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import * as lightBase from './themes/light.js';
export { schema } from './themes/schema.js';
export { themePropType } from './utils/theme-prop-type.js';
// HACK: Copy the theme, otherwise, it gets exported as 'module'.
const light = Object.assign({}, lightBase);
export { light };
